import { csvFormatRow, csvParseRows } from 'd3'
import { createHttpRequest } from '../utils/services'
export const userService = {
    /**
     * login login user
     * @param accessCode
     * @returns
     * @memberof userService
     * @returns token
     * 	*/
    async login(accessCode: string) {
        const res = await createHttpRequest('auth/login', { params: { accessCode }, method: 'POST' })
        return res
    },
    /**
     * refreshToken refresh token
     *
     * @return token
     */
    async refreshToken() {
        const res = await createHttpRequest('auth/refresh', { params: {}, method: 'POST' })
        return res
    },
    /**
     * loginGuest login guest
     *
     * @return {*}
     */
    async loginGuest() {
        const email = uuidv4() + '-guest@luckysit.com'
        const res = await createHttpRequest('auth/login/guest', { params: { email }, method: 'POST' })
        if (!res.error) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * loginGuest login guest
     * @param email
     * @return {*}
     */
    async loginMail(email: string) {
        const res = await createHttpRequest('auth/login/guest', { params: { email }, method: 'POST' })
        if (!res.error) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * convertGuest convert guest to user
     *
     * @param {string} email
     * @return {*}
     */
    async convertGuest(email: string) {
        const res = await createHttpRequest('users/me', { params: { firstName: '_', lastName: '_', email }, method: 'PATCH' })
        if (!res.error.length) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * me get user info
     *
     * @return user
     */
    async me() {
        const res = await createHttpRequest('users/me', { method: 'GET' })
        if (!res.error) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * updateMe update user info
     * @param params
     * @returns
     */
    async updateMe(params: any) {
        const res = await createHttpRequest('users/me', { params, method: 'PATCH' })
        if (!res.error.length) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * getAccessCode get access code
     *
     * @param {*} params
     * @return {*}
     */
    async getAccessCode(params: any) {
        const res = await createHttpRequest('access-codes', { params, method: 'POST' })
        if (!res.error) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * deleteUser delete user
     *
     * @param {*} params
     * @return {*}
     */
    async deleteUser(params: any) {
        const res = await createHttpRequest('users/me', { params, method: 'DELETE' })
        if (!res.error?.length) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * updateEmail update email
     * @param params
     * @returns
     * @memberof userService
     * @returns user
     * */
    async updateEmail(params: any) {
        const res = await createHttpRequest('users/me/email', { params, method: 'PATCH' })
        if (!res.error.length) {
            return res
        } else {
            return { error: res.error }
        }
    },
    /**
     * updateEmailGain update email
     * @param params
     * @returns
     * @memberof userService
     * @returns user
     * */
    async updateEmailGain(params: any) {
        const res = await createHttpRequest('users/me/email/gain', { params, method: 'PATCH' })
        if (!res.error.length) {
            return res
        } else {
            return { error: res.error }
        }
    },

    async userExtract(email:any, event:string, area:string, game:string, name:string, firstName:string, optin:boolean,slug:string){

        const data = {
            cs: slug,
            event:event,
            area:area,
            game:game,
            userEmail: email,
            userName: name,
            userFirstname: firstName,
            optin: optin,
        };
    
        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data) // Convertir l'objet data en chaîne JSON
        };
    
    
        try {
            const response = await fetch('https://80hfp1xk24.execute-api.eu-west-3.amazonaws.com/dev/users', params);
            if (response.ok) {
                console.log("Data sent successfully:", await response.json());
              } else {
                console.error("Error sending data:", response.statusText);
              }
            } catch (error) {
              console.error("Error sending data:", error);
            }
    }
}
/**
 * uuidv4 create uuid
 *
 * @return {*}
 */
function uuidv4() {
    return (1e3 + 4e3 + 8e3 + 1e11)
        .toString()
        .replace(/[06]/g, (c) =>
            ((c as any) ^ ((((crypto.getRandomValues(new Uint8Array(1))[0] as any & 15) >> c) as number) / 4)).toString(16)
        )
}
